#hero_section {
  background-image: url("../../assets/images/blackBg.png");
  padding: 25px 0 60px 0;
  // background-color: rgb(0, 0, 0);
  background-size: cover;
  background-position: center;
  .waka_logo {
    margin: 0 auto;
    display: block;
  }
  .small_lbl {
    background-color: rgba(128, 126, 126, 0.41);
    color: #fff;
    padding: 12px 16px;
    border-radius: 100px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    margin-top: 75px;
    display: inline-block;
  }
  h1 {
    color: #fff;
    text-align: center;
    font-weight: 700;
    margin: 12px 0 24px 0;
  }
  .desc {
    color: #b6b8bc;
    font-size: 20px;
    line-height: 25px;
    font-weight: 500;
    text-align: center;
    width: 100%;
    max-width: 693px;
    margin: 0 auto 32px;
    display: block;
  }
  .waka_features {
    margin-top: 130px;
    position: relative;
    background-image: url("../../assets/images/redBg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 37px 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    overflow: hidden;
    // &::before{
    //     position: absolute;
    //     content: '';
    //     width: 300px;
    //     height: 250px;
    //     background-image: url('../../assets/images/ornamentTop.png');
    //     background-position: center;
    //     background-size: 100% 100%;
    //     background-repeat: no-repeat;
    //     top: 50px;
    //     left: 0px;
    //     z-index: 0;
    // }
    // &::after{
    //     position: absolute;
    //     content: '';
    //     width: 300px;
    //     height: 250px;
    //     background-image: url('../../assets/images/ornamentBottom.png');
    //     background-position: center;
    //     background-size: 100% 100%;
    //     background-repeat: no-repeat;
    //     bottom: 30px;
    //     right: 0;
    //     z-index: 0;
    // }
    .waka_features_slider {
      position: relative;
      z-index: 1;
      .slick-dots {
        bottom: 0;
        li {
          width: unset;
          button {
            background-color: rgba(247, 247, 247, 0.4);
            border-radius: 50px;
            overflow: hidden;
            width: 6px;
            height: 6px;
            &::before {
              // color: #fff;
              // width: 100%;
              // height: 100%;
              // content: '';
              display: none;
            }
          }
        }
        li.slick-active {
          button {
            background-color: #fff;
          }
        }
      }
      .slick-slide > div {
        .features_item {
          text-align: center;
          img {
            display: block;
            margin: 0 auto;
          }
          h5 {
            margin-top: 10px;
            color: #fff;
            font-weight: 500;
            position: relative;
            z-index: 1;
          }
        }
      }
    }
    .title {
      color: #fff;
      font-weight: 600;
      font-style: normal;
      text-align: center;
      position: relative;
      z-index: 1;
    }
  }
}
#keyFeatures_section {
  padding: 50px 0 120px;
  .title {
    color: #000;
    text-align: center;
    font-weight: 700;
    margin-bottom: 60px;
    font-size: 72px;
  }
  .key_features_items {
    .key_features_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 24px 0;
      background-color: #f8f8f8;
      padding: 54px 60px;
      border-radius: 20px;
      h4 {
        color: #eb1c24;
        font-weight: 600;
        margin: 0;
      }
      .text-con {
        display: flex;
        flex-direction: column;
        .num_det {
          display: flex;
          align-items: baseline;
          .num {
            margin-right: 40px;
          }
          .details {
            h4 {
              margin-bottom: 16px;
              margin-top: 0;
            }
            ul {
              padding-left: 10px;
              li {
                font-family: "Inter", sans-serif;
                color: #5c5e60;
                font-size: 18px;
                margin-bottom: 5px;
              }
            }
          }
        }
      }
      .img_con {
        margin-left: 40px;
        img {
          border-radius: 20px;
        }
      }
    }
    .key_features_item:nth-child(even) {
      .text-con {
        order: 2;
      }
      .img_con {
        margin-left: 0;
        margin-right: 40px;
      }
    }
  }
}

#statistic_section {
  padding: 80px 0;
  background-color: rgba(235, 28, 36, 0.1);
  position: relative;
  svg.circle {
    position: absolute;
    left: 12%;
    top: 10%;
  }
  svg.sprite1 {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  svg.sprite2 {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .my_container {
    position: relative;
    z-index: 1;
  }
  h4.details {
    font-size: 30px;
    font-weight: 500;
    color: #000;
    text-align: center;
    letter-spacing: -0.6px;
    margin-top: 0;
  }
  .statistics_items {
    background-color: #eb1c24;
    border-radius: 20px;
    padding: 32px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .statistics_item {
      text-align: center;
      .num {
        h2 {
          color: #fff;
          font-size: 72px;
          font-weight: 700;
          letter-spacing: -1.44px;
          margin: 0;
        }
      }
      .label {
        p {
          color: #fff;
          margin-top: 12px;
          margin-bottom: 0;
          font-size: 24px;
          line-height: 30px;
        }
      }
    }
  }
}
#tracking_section {
  padding: 80px 0;
  background-color: rgba(235, 28, 36, 0.1);
  .my_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .text_con {
      width: 50%;
      .title {
        font-size: 64px;
        line-height: 76px;
        margin: 0;
      }
      p {
        font-size: 24px;
        line-height: 30px;
        margin-top: 20px;
      }
    }
    .img_con {
      width: 50%;
      img {
        width: 100%;
      }
    }
  }
}
#clockwise_section {
  padding: 50px 0;
  .title {
    text-align: center;
    font-size: 72px;
    font-weight: 700;
    margin-top: 0;
  }
  .clock_wise_feature_items {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    .clock_wise_feature_item {
      background-color: #f8f8f8;
      padding: 24px;
      width: 100%;
      max-width: 460px;
      margin: 20px;
      border-radius: 15px;
      .heading {
        display: flex;
        align-items: center;
        .img {
          margin-right: 12px;
        }
        .title_head {
          p {
            font-size: 20px;
            line-height: 25px;
            font-weight: 600;
            color: #000;
          }
        }
      }
      .desc {
        ul {
          li {
            font-family: "Inter", sans-serif;
            color: #6d7079;
            font-size: 20px;
            font-weight: 400;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
}
#contact_section {
  padding: 50px 0;
  .form_con {
    display: flex;
    border-radius: 20px;
    overflow: hidden;
    .info {
      padding: 50px;
      width: 50%;
      text-align: center;
      background-image: url("../../assets/images/contactBg.png");
      background-size: cover;
      background-position: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      img {
        width: 100%;
        max-width: 160px;
        display: block;
        margin: 0 auto;
      }
      h5 {
        color: #fff;
      }
      p {
        font-family: "Inter", sans-serif;
        color: #b6b8bc;
        text-align: center;
        font-size: 16px;
        width: 100%;
        max-width: 213px;
        display: block;
        margin: 0 auto;
        font-weight: 500;
      }
    }
    .form_fields {
      padding: 50px 30px;
      width: 50%;
      background-color: #f8f8f8;
      .field {
        display: flex;
        flex-direction: column;
        margin: 20px 0;
        width: 100%;
        label {
          margin-bottom: 5px;
          font-family: "Inter", sans-serif;
        }
      }
      .submitForm {
        border-radius: 5px !important;
        padding: 14px 15px !important;
        width: 100%;
      }
    }
  }
}
#footer_section {
  background-image: url("../../assets/images/footerBlackBg2.jpg");
  //padding: 50px 0 0;
  background-repeat: repeat;
  background-size: 100% 100%;
  // .req_demo {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   padding: 0 80px;
  //   .text,
  //   .buttonCon {
  //     width: 50%;
  //   }
  //   .text {
  //     h3 {
  //       color: #fff;
  //     }
  //   }
  //   .buttonCon {
  //     .d_block {
  //       text-align: right;
  //     }
  //   }
  // }
  .copyright {
    font-family: "Inter", sans-serif;
    color: #b6b8bc;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    padding: 50px 0;
    margin: 0;
  }
}

// responsive
// @media  screen and (max-width: 1920px) {}
// @media  screen and (max-width: 1440px) {}
@media screen and (max-width: 1280px) {
  #clockwise_section {
    .clock_wise_feature_items {
      .clock_wise_feature_item {
        max-width: 400px;
      }
    }
  }
}
@media screen and (max-width: 1080px) {
  #keyFeatures_section {
    .key_features_items {
      .key_features_item {
        .text-con {
          width: 55%;
        }
        .img_con {
          width: 45%;
          img {
            width: 100%;
          }
        }
      }
    }
  }
  #statistic_section {
    h4.details {
      font-size: 27px;
      line-height: 30px;
    }
  }
  #tracking_section {
    .my_container {
      .text_con {
        width: 50%;
        .title {
          font-size: 50px;
          line-height: 55px;
        }
        p {
          font-size: 20px;
          line-height: 25px;
          margin-top: 15px;
        }
      }
      .img_con {
        img {
        }
      }
    }
  }
  #clockwise_section {
    .clock_wise_feature_items {
      .clock_wise_feature_item {
        max-width: 320px;
      }
    }
  }
  #statistic_section {
    .statistics_items {
      flex-wrap: wrap;
      .statistics_item {
        width: 50%;
        margin: 20px 0;
      }
    }
  }
}
@media screen and (max-width: 980px) {
  #hero_section {
    p.small_lbl {
      font-size: 12px;
      margin-top: 50px;
    }
    .waka_features {
      margin-top: 100px;
      padding: 0;
    }
  }
  #keyFeatures_section {
    padding: 50px 0;
    .title {
      font-size: 50px;
      margin-top: 0;
      margin-bottom: 40px;
    }
    .key_features_items {
      .key_features_item {
        display: block;
        padding: 25px;
        .text-con,
        .img_con {
          width: 100%;
        }
        .img_con {
          margin-left: 0;
          margin-top: 20px;
        }
      }
    }
  }
  #statistic_section {
    padding: 50px 0;
    h4.details {
      font-size: 24px;
      line-height: 30px;
    }
  }
  #tracking_section {
    .my_container {
      .text_con {
        width: 50%;
        .title {
          font-size: 45px;
          line-height: 50px;
        }
        p {
          font-size: 18px;
          line-height: 23px;
          margin-top: 10px;
        }
      }
      .img_con {
        img {
        }
      }
    }
  }
  #clockwise_section {
    h2.title {
      font-size: 60px;
      margin-top: 0;
      margin-bottom: 30px;
    }
    .clock_wise_feature_items {
      .clock_wise_feature_item {
        max-width: 280px;
      }
    }
  }
  #contact_section {
    padding-top: 0;
  }
  #footer_section {
    // padding: 20px 0 0;
    .req_demo {
      display: block;
      padding: 0 20px;
      .text {
        h3 {
          font-size: 30px;
          line-height: 35px;
        }
      }
      .buttonCon {
        .d_block {
          text-align: center;
          margin: 10px 0;
        }
      }
      .text,
      .buttonCon {
        width: 100%;
        text-align: center;
      }
    }
    .copyright {
      padding: 20px 0;
      font-size: 14px;
    }
  }
}
@media screen and (max-width: 767px) {
  #hero_section {
    p.small_lbl {
      border-radius: 15px;
    }
    .waka_features {
      margin-top: 80px;
    }
  }
  #tracking_section {
    padding: 40px 0 0;
    .my_container {
      display: block;
      .text_con {
        width: 100%;
        .title {
          font-size: 40px;
          line-height: 45px;
          text-align: center;
        }
        p {
          text-align: center;
          margin-bottom: 0;
        }
      }
      .img_con {
        width: 100%;
        img {

        }
      }
    }
  }
  #clockwise_section {
    .clock_wise_feature_items {
      .clock_wise_feature_item {
        max-width: 100%;
      }
    }
  }
  #contact_section {
    .form_con {
      display: block;
      border-radius: 0;
      .info,
      .form_fields {
        width: unset;
        padding: 40px 20px;
        display: block;
      }
    }
  }
}
@media screen and (max-width: 640px) {
  #hero_section {
    .desc {
      font-size: 17px;
      max-width: 500px;
    }
    .waka_features {
      margin-top: 70px;
    }
  }
  #keyFeatures_section {
    .key_features_items {
      .key_features_item {
        h4 {
          line-height: 35px;
        }
        .text-con {
          .num_det {
            .num {
              margin-right: 30px;
            }
            .details {
              ul {
                padding-left: 25px;
              }
            }
          }
        }
      }
    }
  }
  #statistic_section {
    .statistics_items {
      .statistics_item {
        width: 100%;
        margin-bottom: 24px;
        margin-top: 0px;
        .label {
          p {
            font-size: 16px;
            line-height: 22px;
          }
        }
        .num {
          h2 {
            font-size: 48px;
          }
        }
      }
      .statistics_item:nth-child(4) {
        margin-bottom: 0;
      }
    }
  }
 
}
@media screen and (max-width: 575px) {
  #hero_section {
    .waka_features {
      margin-top: 60px;
    }
  }
}
@media screen and (max-width: 440px) {
  #hero_section {
    .waka_features {
      margin-top: 50px;
    }
  }
  #keyFeatures_section {
    padding: 40px 0;
    .title {
      font-size: 40px;
      margin-bottom: 30px;
    }
    .key_features_items {
      .key_features_item {
        padding: 25px 15px;
        h4 {
          font-size: 30px;
        }
        .text-con {
          .num_det {
            .num {
              margin-right: 15px;
            }
            .details {
              ul {
                li {
                  margin-bottom: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
  #tracking_section {
    .my_container {
      .text_con {
        .title {
          font-size: 30px;
          line-height: 35px;
        }
      }
    }
  }
  #clockwise_section {
    h2.title {
      font-size: 40px;
      margin-bottom: 20px;
    }
  }
  #footer_section {
    .copyright {
      font-size: 11px;
      line-height: 14px;
    }
  }
}
@media screen and (max-width: 320px) {
  #hero_section {
    .waka_features {
      margin-top: 40px;
    }
  }
  #keyFeatures_section {
    .title {
      font-size: 35px;
      margin-bottom: 25px;
    }
  }
  #contact_section {
    .email_phone_con {
      display: block;
      .field {
        width: 100%;
        margin-right: 0 !important;
      }
    }
  }
}
@media screen and (max-width: 280px) {
}
