// responsive
// @media  screen and (max-width: 1920px) {}
// @media  screen and (max-width: 1440px) {}
// @media  screen and (max-width: 1280px) {}
// @media  screen and (max-width: 1100px) {}
// @media  screen and (max-width: 1080px) {}
// @media  screen and (max-width: 980px) {}
// @media  screen and (max-width: 767px) {}
// @media  screen and (max-width: 640px) {}
// @media  screen and (max-width: 575px) {}
// @media  screen and (max-width: 440px) {}
// @media  screen and (max-width: 320px) {}
// @media  screen and (max-width: 280px) {}

// #root {
//   display: flex;
//   justify-content: center;
//   flex-grow: 1;
//   height: "100%";
//   align-items: center;
// }
body {
  margin: 0;
}
.d_block {
  display: block;
  text-align: center;
}
.my_container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 5px;
}
.colorRed {
  color: #eb1c24;
}
// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// .font-poppins {
//   letter-spacing: unset !important;
// }
.font-weight700 {
  font-weight: 700 !important;
}

h1 {
  font-family: "Inter", sans-serif;
  font-size: 72px;
  line-height: 76px; /* 105.556% */
}
h2 {
    font-family: "Inter", sans-serif;
    font-size: 50px;
    line-height: 55px; /* 105.556% */
}
h3 {
  font-family: "Inter", sans-serif;
  font-size: 44px;
  line-height: 50px;
}
h4 {
  font-family: "Inter", sans-serif;
  font-size: 32px;
  line-height: 40px;
}
h5 {
    font-family: "Inter", sans-serif;
    font-size: 24px;
    line-height: 32px;
}
h6 {
 
}
p {
  font-family: "Inter", sans-serif;
  font-size: 14px;
  line-height: 20px; /* 142.857% */
}


// @media  screen and (max-width: 1920px) {}
// @media  screen and (max-width: 1440px) {}
@media  screen and (max-width: 1280px) {
  .my_container {
    max-width: 1060px;
  }
}

@media  screen and (max-width: 1080px) {
  h1 {
    font-size: 60px;
    line-height: 65px;
  }
  .my_container {
    max-width: 960px;
  }
}
@media  screen and (max-width: 980px) {
  h1 {
    font-size: 50px;
    line-height: 55px;
  }
  h5 {
    font-size: 20px;
    line-height: 25px;
}
  .my_container {
    max-width: 747px;
  }
}
@media  screen and (max-width: 767px) {
  h1 {
    font-size: 40px;
    line-height: 45px;
  }
  .my_container {
    max-width: 620px;
  }
  
}
@media  screen and (max-width: 640px) {
  h1 {
    font-size: 36px;
    line-height: 37px;
  }
  .my_container {
    max-width: 560px;
  }
}
@media  screen and (max-width: 575px) {
  .my_container {
    max-width: 420px;
  }
}
@media  screen and (max-width: 440px) {
  .my_container {
    max-width: 300px;
  }
}
@media  screen and (max-width: 320px) {
  .my_container {
    max-width: 90%;
  }
}
@media  screen and (max-width: 280px) {}