.cust_btn{
    font-size: 18px !important;
    line-height: 18px !important;
    box-shadow: unset !important;
    text-transform:capitalize !important;
    font-weight: 500 !important;
    padding: 20px 24px !important;
    font-family: "Inter", sans-serif;
    border-radius: 100px !important;
    svg{
        margin: 0 5px;
    }
}


// responsive
// @media  screen and (max-width: 1920px) {}
// @media  screen and (max-width: 1440px) {}
// @media  screen and (max-width: 1280px) {}
// @media  screen and (max-width: 1100px) {}
// @media  screen and (max-width: 1080px) {}
// @media  screen and (max-width: 980px) {}
@media  screen and (max-width: 767px) {
    .cust_btn{
        font-size: 16px !important;
        line-height: 18px !important;
        padding: 15px 20px !important;
    }
}
@media  screen and (max-width: 640px) {
    .cust_btn{
        font-size: 14px !important;
        line-height: 16px !important;
        padding: 10px 15px !important;
    }
}
@media  screen and (max-width: 575px) {
    .cust_btn{
        font-size: 13px !important;
        line-height: 15px !important;
        padding: 8px 12px !important;
    }
}
@media  screen and (max-width: 440px) {}
@media  screen and (max-width: 320px) {}
@media  screen and (max-width: 280px) {}
